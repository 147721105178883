<template>
  <div>
    <b-table
      id="beacon-table"
      ref="beacon-table"
      responsive="sm"
      :fields="fields"
      :items="getDeviceBeacon"
      :busy="isBusy"
      table-class="table-head-custom table-vertical-center mt-5"
    >
      <template v-slot:cell(beacon)="data">
        <div class="d-flex pl-3 align-items-center">
          <div class="symbol symbol-40 symbol-light mr-5">
            <span class="symbol-label svg-icon svg-icon-primary">
              <inline-svg
                class="h-100 align-self-end"
                src="/media/svg/icons/Devices/LTE2.svg"
              />
            </span>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <span class="font-weight-bolder">
              {{ data.item.beacon }}
            </span>
            <span class="font-weight-bold">
              {{ data.item.serialnum }}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showBeacon(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-details button -->

          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-if="getSuperAdminPermission"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteEmployeeDetails"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="refresh"
        v-model="items.current_page"
        :total-rows="items.total"
        :per-page="items.per_page"
        aria-controls="beacon-table"
      ></b-pagination>
    </div>
    <DeviceBeaconModal></DeviceBeaconModal>
  </div>
</template>

<script>
//import FacialDeviceTableUserModal from "@/modules/device/components/facial/FacialDeviceTableUserModal";
import DeviceBeaconModal from "@/modules/device/components/facial/DeviceBeaconModal";
import { mapState, mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import swal from "sweetalert";

export default {
  name: "DeviceBeaconTable",
  props: ["reload"],
  components: { AppLoader, DeviceBeaconModal },
  mounted() {},
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "beacon", label: this.$t("DEVICE.FACIAL.PAGE.BEACON") },
        { key: "name", label: this.$t("SIDEBAR.EMPLOYEE") },
        { key: "actions", label: this.$t("DEVICE.FACIAL.PAGE.ACTIONS") },
      ],
    };
  },
  methods: {
    handlePageChange(value) {
      console.log(value);
      this.$store
        .dispatch("getDepartment", {
          page_number: value,
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    showBeacon(row) {
      console.log("Selected Leave ", row);
      this.$bvModal.show("beacon-details-modal");
      this.$store.dispatch("assignEmployeeId", row.item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },
    deleteEmployeeDetails() {
      swal({
        title: this.$t("ALERT.SURE"),
        text: this.$t("ALERT.WARNING"),
        icon: "warning",
        showCancelButton: true,
        dangerMode: true,
        buttons: [this.$t("BUTTON.CANCEL"), this.$t("BUTTON.OKAY")],
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then((willDelete) => {
        if (willDelete) {
          swal(this.$t("ALERT.CONFIRM"), {
            icon: "success",
          });
        }
      });
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.CompanyModule.department.list.items,
    }),
    ...mapGetters(["getDeviceBeacon", "getSuperAdminPermission"]),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.$refs["beacon-table"].refresh();
    },
  },
};
</script>

<style scoped></style>
