<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold py-5">{{ title }}</h4>
    <!-- begin: Facial Device Form    -->
    <b-form
      class="form"
      id="facial-device-form"
      v-on:submit.prevent="addFacialDevice"
    >
      <!-- begin: Name     -->
      <b-form-group label-class="text-bolder">
        {{ $t("BEACON.BEACON1") }}
        <b-form-input
          id="facial-device-name-input"
          class="form-control h-auto"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
          :placeholder="$t('BEACON.BEACON2')"
        >
        </b-form-input
      ></b-form-group>
      <!-- end: Name     -->

      <!-- begin: Serial Number     -->
      <b-form-group label-class="text-bolder">
        {{ $t("DEVICEFORM.SERIALNUM") }}
        <b-form-input
          id="facial-device-name-input"
          class="form-control h-auto"
          v-model="$v.form.code.$model"
          :state="validateState('code')"
          :placeholder="$t('DEVICEFORM.SERIALNUM1')"
        >
        </b-form-input
      ></b-form-group>
      <!-- end: Serial Number     -->

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <button
          type="submit"
          ref="facial-register-form-submit"
          class="btn btn-primary font-weight-bolder"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </button>
      </div>
      <!--end::Action-->
    </b-form>
    <!-- end: Facial Device Form    -->
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";

export default {
  name: "DeviceBeaconForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        name: "",
        code: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      code: {
        required,
        minLength: minLength(6),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    addFacialDevice() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const submitButton = this.$refs["facial-register-form-submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch("registerDevice", {
          name: this.form.name,
          serial_number: this.form.code,
        })
        .then((response) => this.$emit("success", response.data))
        .catch((reason) => this.$emit("failure", reason))
        .finally(() =>
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          )
        );
    },
  },
  computed: {
    ...mapState({
      items: (state) =>
        state.DeviceModule.FacialStore.FacialFormStore.responses,
    }),
  },
};
</script>

<style scoped></style>
