<template>
  <b-form class="form" v-on:submit.prevent="onSubmit">
    <!-- begin: Facial Device Form    -->
    <b-form-group
      id="facial-device-form"
      label-cols-lg="2"
      :label="$t('BEACON.BEACON1')"
      label-for="facial-device-form"
    >
      <b-form-input
        id="facial-device-form"
        :placeholder="$t('BEACON.BEACON2')"
        v-model="$v.form.name.$model"
        :state="validateState('name')"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.name.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="facial-device-name-input"
      label-cols-lg="2"
      :label="$t('DEVICEFORM.SERIALNUM')"
      label-for="facial-device-name-input"
    >
      <b-form-input
        id="facial-device-name-input"
        :placeholder="$t('DEVICEFORM.SERIALNUM1')"
        v-model="$v.form.code.$model"
        :state="validateState('code')"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.code.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="employees"
      label-for="employees"
      label-cols-lg="2"
      :label="$t('EMPLOYEE.EMP')"
    >
      <b-form-select
        id="employees"
        v-model="$v.form.employee.$model"
        :options="employees"
        :state="validateState('employee')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.employee.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <!-- end:date  -->
    <div class="d-flex justify-content-end border-top pt-3">
      <b-button type="reset" variant="light" class="mr-2 font-size-h6">
        {{ $t("EMPLOYEES.DETAILS.RESET") }}</b-button
      >

      <button
        ref="employee-general-button"
        class="btn btn-primary text-white font-weight-light font-size-h6"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </button>
    </div>
  </b-form>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import swal from "sweetalert";

export default {
  name: "DeviceBeaconEditForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        name: "",
        code: "",
        employee: "",
      },
      employees: [
        { value: "1", text: "Siti Zulaikha" },
        { value: "2", text: "Shawn" },
        { value: "3", text: "Sean" },
      ],
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      code: {
        required,
        minLength: minLength(6),
      },
      employee: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Application Form:", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.formBusy = !this.formBusy;
      this.$store
        .dispatch("submitPendingApplication", this.form)
        .then(() => {
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
        })
        .finally(() => (this.formBusy = !this.formBusy));
    },
    uploadPicture() {
      this.imageBusy = !this.imageBusy;
      this.$store
        .dispatch("uploadEmployeeImage", this.image)
        .then((res) => {
          console.log(res.data);
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
          this.image_url = res.data.url;
        })
        .finally(() => (this.imageBusy = !this.imageBusy));
    },
  },
  mounted() {
    this.$store.dispatch("fetchLeaveApplicationForm").then((response) => {
      const formData = response.data.data;

      console.log("Employment date", formData.date);
      this.form = {
        id: formData.id,
        name: formData.name,
        code: formData.code,
        employee: formData.employee.trim(),
      };
      this.image_url = formData.image_url;
      console.log("Image URL is", this.image_url);
    });
  },
  computed: {
    ...mapState({
      employeeId: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.employeeId,
    }),
  },
};
</script>

<style scoped></style>
