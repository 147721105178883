<template>
  <div>
    <b-card v-if="getDevMode">
      <!-- begin: header Content     -->
      <div class="d-flex justify-content-between flex-wrap">
        <div class="flex-row">
          <h3 class="card-label font-weight-bolder text-dark">
            {{ $t("DEVICE.FACIAL.PAGE.BEACON") }}
          </h3>
          <span class="text-muted mt-3 font-weight-bold">
            {{ $t("BEACON.BEACON") }}</span
          >
        </div>
        <div class="card-toolbar">
          <b-button
            variant="primary"
            class="font-weight-bolder"
            v-b-toggle.facial-device-list-sidebar
          >
            {{ $t("DEVICE.FACIAL.PAGE.BUTTON") }}
          </b-button>
          <b-sidebar
            ref="facial-device-list-sidebar-ref"
            id="facial-device-list-sidebar"
            backdrop
            right
            lazy
            :title="$t('BEACON.HEADER')"
            sidebar-class="offcanvas"
            header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
            bg-variant="white"
          >
            <template v-slot:header-close>
              <button
                class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
              >
                <i class="ki ki-close icon-xs"></i>
              </button>
            </template>
            <div class="px-10 mt-5">
              <DeviceBeaconForm></DeviceBeaconForm>
            </div>
          </b-sidebar>
        </div>
      </div>
      <!-- end: header Content     -->
      <div>
        <b-alert
          variant="info"
          :show="dismissCountDown"
          fade
          dismissible
          @dismissed="dismissCountDown = 0"
        >
          {{ successMessage }}
        </b-alert>
      </div>

      <DeviceBeaconTable :reload="reload"></DeviceBeaconTable>
    </b-card>
    <b-card v-if="!getDevMode">
      <div class="d-flex justify-content-between flex-wrap"></div>
      <div class="flex-row">
        <AppEmptyList
          text-top="Under Construction"
          text-bottom="Please Come Back Later"
        >
        </AppEmptyList>
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DeviceBeaconTable from "@/modules/device/components/facial/DeviceBeaconTable";
import DeviceBeaconForm from "@/modules/device/components/facial/DeviceBeaconForm";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "DeviceBeaconPage",
  components: { AppEmptyList, DeviceBeaconForm, DeviceBeaconTable },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.DEVICE") },
      { title: this.$t("SIDEBAR.FACIAL"), route: "/device/facial" },
    ]);
  },
  data() {
    return {
      reload: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      formData: "",
      successMessage: "Successfully added Facial Device.",
    };
  },
  methods: {
    closeSideBar(data) {
      this.formData = data;
      const sideBar = this.$refs["facial-device-list-sidebar-ref"];
      sideBar.hide();
      this.showAlert();
      this.reload = !this.reload;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  computed: {
    getDevMode() {
      let mode = false;
      if (process.env.NODE_ENV === "development") {
        mode = true;
      }

      return mode;
    },
  },
};
</script>

<style scoped></style>
